import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import Box from "@components/styled/Box"
import Container from "@components/styled/Container"
import Layout from "@components/layout"
import SEO from "@components/seo"
import { rem } from "@src/theme"
// A great library for fuzzy filtering/sorting items
import ProductCard from "@components/ProductCard"
import Columns from "@components/styled/Columns"
import Column from "@components/styled/Column"
import SelectInput from "@components/Select"
import { fetchProducts } from "@helpers/airtable"
import TextInput from "@components/TextInput"

// ****************//
import FilterActionButtons from "@components/FilterActionButtons"
import {
  getValueFromId,
  mapOptions,
  getFilterCallbacks,
  getFilterOptions,
  getUnselectedFilterOptions
} from "@helpers/data"
import { ClipLoader } from "react-spinners"
import { authCheckRedirect } from "@helpers/auth"
import AddButton from "@components/AddButton"

const FILTER_BY_OPTIONS = [
  { value: "name", label: "Product name" },
  { value: "type", label: "Product type" },
  { value: "sector", label: "Sector" },
  { value: "state", label: "State" },
  { value: "stage", label: "Current stage" }
]

const ViewProjects = ({ data, ...props }) => {
  const [recordsData, setRecordsData] = useState([])
  const [filters, setFilters] = useState([{ type: "name", value: "" }])
  const [loading, setLoading] = useState(false)
  const stageOptions = mapOptions(data.airtable_stage.edges)
  const productTypeOptions = mapOptions(data.airtable_productType.edges)

  const stateTerritoryOptions = mapOptions(data.airtable_stateTerritory.edges)
  const sectorOptions = mapOptions(data.airtable_sector.edges)

  useEffect(() => {
    authCheckRedirect()
    const fetchData = async () => {
      const {
        airtable_stage,
        airtable_productType,
        airtable_stateTerritory,
        airtable_sector
      } = data
      setLoading(true)
      let dataFromAirtable = await fetchProducts()
      dataFromAirtable = dataFromAirtable.map(record => ({
        id: record.id,
        name: record.fields["Product name"] || "",
        type: getValueFromId(
          airtable_productType.edges,
          record.fields["Product type"]
        ),
        state: getValueFromId(
          airtable_stateTerritory.edges,
          record.fields["State / Territory"]
        ),
        sector: getValueFromId(airtable_sector.edges, record.fields["Sector"]),
        stage: getValueFromId(
          airtable_stage.edges,
          record.fields["Current stage"]
        )
      }))
      setRecordsData(dataFromAirtable)
      setLoading(false)
    }

    fetchData()
  }, [])

  // ****************//
  const onFilterByChange = (val, index) => {
    let updatedFilters = [...filters]

    updatedFilters[index] = {
      type: val,
      value: ""
    }
    setFilters(updatedFilters)
  }
  // ****************//
  const onFilterChoiceChange = (val, index) => {
    let updatedFilters = [...filters]

    updatedFilters[index] = {
      ...updatedFilters[index],
      value: val
    }
    setFilters(updatedFilters)
  }
  // ****************//
  const handleTextFilterChange = (val, index) => {
    onFilterChoiceChange(val, index)
  }
  // ****************//
  const handleDropdownFilterChange = (val, index) => {
    onFilterChoiceChange(val, index)
  }

  const getDropdownOption = type => {
    if (type === "type") {
      return productTypeOptions
    }
    if (type === "sector") {
      return sectorOptions
    }
    if (type === "state") {
      return stateTerritoryOptions
    }
    if (type === "stage") {
      return stageOptions
    }

    return []
  }

  // ****************//
  const addFilter = () => {
    const availableOptions = getUnselectedFilterOptions(
      filters,
      FILTER_BY_OPTIONS
    )
    setFilters([...filters, { type: availableOptions[0].value, value: "" }])
  }
  // ****************//
  const clearFilters = () => {
    setFilters([{ type: "name", value: "" }])
  }

  const renderFilterInput = (type, value, index) => {
    if (type === "name") {
      return (
        <div>
          <label htmlFor="name-filter"></label>
          <TextInput
            name="name-filter"
            value={value}
            onChange={e => handleTextFilterChange(e.target.value, index)}
            onBlur={() => {}}
          />
        </div>
      )
    }

    return (
      <SelectInput
        name={type}
        value={value}
        onChange={(name, val) => handleDropdownFilterChange(val, index)}
        options={getDropdownOption(type)}
      />
    )
  }

  return (
    <Layout uri={props.uri}>
      <SEO title="View Products" />

      <Container pt={7}>
        <Box
          as="h1"
          fontSize={rem(28)}
          mb={4}
          p={4}
          pr={6}
          bg="blue"
          color="white"
          display="inline-block"
        >
          Products
        </Box>

        <Box as="section" my={6}>
          <Box display="flex" alignItems="flex-end">
            <Box mr={5}>
              <Box display="flex" alignItems="center" mb={3}>
                <Box as="label" display="block" mr={4}>
                  Filter by
                </Box>

                <FilterActionButtons
                  onAdd={addFilter}
                  onClear={clearFilters}
                  addDisabled={filters.length === FILTER_BY_OPTIONS.length}
                />
              </Box>

              {filters.map(({ type, value }, index) => (
                <Box width="100%" display="flex" mb={5}>
                  <Box width={rem(300)} mr={5}>
                    <SelectInput
                      name={type}
                      value={type}
                      onChange={(name, val) => onFilterByChange(val, index)}
                      options={getFilterOptions(filters, FILTER_BY_OPTIONS)}
                    />
                  </Box>
                  <Box width={rem(300)}>
                    {renderFilterInput(type, value, index)}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <AddButton to="/add-product" />
        <Columns>
          {!loading && recordsData.length ? (
            getFilterCallbacks(filters, recordsData, [
              "name",
              "presentation_name"
            ])
              .reduce((d, f) => d.filter(f), recordsData)
              .map(record => (
                <Column key={record.id} width={[1, 1 / 2]} mb={2}>
                  <ProductCard {...record} />
                </Column>
              ))
          ) : !loading && !recordsData.length ? (
            <Column width={1}>
              <Box textAlign="center">
                There aren't any products, add one{" "}
                <Link to="add-product">here</Link>
              </Box>
            </Column>
          ) : (
            <Column width={1}>
              <Box display="flex" justifyContent="center">
                <ClipLoader sizeUnit="px" size={150} />
              </Box>
            </Column>
          )}
        </Columns>
      </Container>
    </Layout>
  )
}

export default ViewProjects

export const query = graphql`
  query {
    airtable_stateTerritory: allAirtable(
      filter: { table: { eq: "DL_State_Territory" } }
    ) {
      edges {
        node {
          recordId
          data {
            Name
          }
        }
      }
    }

    airtable_sector: allAirtable(filter: { table: { eq: "DL_SECTOR" } }) {
      edges {
        node {
          recordId
          data {
            Name
          }
        }
      }
    }

    airtable_productType: allAirtable(
      filter: { table: { eq: "DL_Product_Type" } }
    ) {
      edges {
        node {
          recordId
          data {
            Name
          }
        }
      }
    }

    airtable_stage: allAirtable(
      filter: { table: { eq: "DL_Product_Development_Stage" } }
    ) {
      edges {
        node {
          recordId
          data {
            Name
          }
        }
      }
    }
  }
`
