import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Box from "@components/styled/Box"
import { rem } from "@src/theme"
import Columns from "@components/styled/Columns"
import Column from "@components/styled/Column"

const ProductCard = ({ id, name, type, state, stage, sector }) => (
  <Outer to={`/product?id=${id}`}>
    <Box bg="offWhite" height="100%" width="100%" borderRadius="2px">
      <Box p={4}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Box width="95%">
            <Box as="h4" pr={5}>
              {name}
            </Box>
          </Box>
        </Box>

        <Columns>
          <Column width={0.5} mb={4}>
            <Detail>
              <span>Product type</span>
              {type.name}
            </Detail>

            <Detail>
              <span>State</span>
              {state.name}
            </Detail>
          </Column>
          <Column width={0.5} mb={4}>
            <Detail>
              <span>Sector</span>
              {sector.name}
            </Detail>
            <Detail>
              <span>Current stage</span>
              {stage.name}
            </Detail>
          </Column>
        </Columns>
      </Box>
    </Box>
  </Outer>
)

const Outer = styled(Link)`
  text-decoration: none;
  display: block;
  height: 100%;
  width: 100%;
  color: ${p => p.theme.colors.black};
`
const Detail = styled.p`
  margin-bottom: ${p => p.theme.space[4]};
  display: block;
  span {
    display: block;
    text-transform: uppercase;
    font-weight: 600;
    font-size: ${rem(10)};
  }
`

export default ProductCard
